<template>
    <div>
    <div class="card">
        <div class="cart-info">
          <div class="container">
            <div class="row">
                <div class="cart-info-body " style="height:63vh">
                    <div class="alert alert-success">
                        <figure style="margin-bottom: 20px;"><img width="80" :src="'/img/ok_circle.png'" alt="order success image"></figure>
                       <strong>Success!</strong><br>You order has been submitted successfully.
                    </div>
                </div>
             </div>
            </div>
        </div> 
        </div>   
    </div>
</template>

<script>
export default {
    name: 'CartAlert',
    watch: {
            $route() {
                if(this.$route.name == 'cart.alert'){
                    // setTimeout(() => {  this.$router.push({name:"newHome"}); }, 2000);
                }
                
            }
        },
    mounted(){
        setTimeout(() => { 
            //  this.$router.push({name:"newHome"});
            window.location.href='/';
         }, 2000);
        // localStorage.removeItem('userToken');
    }
    
}
</script>


<style>

</style>
